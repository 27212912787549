import React, { useEffect, useRef, useState } from "react";
import Style from "./BaseLayout.module.scss";
import Navbar from "./Navbar";
import Home from "./home/Home";
import About from "./about/About";
import Portfolio from "./portfolio/Portfolio";
import { Route, Routes } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import MatrixBackground from "./MatrixBackground";
import { ModelTraining } from "@mui/icons-material";

export default function BaseLayout() {
  const date = new Date();
  //Check for browser color-scheme default
  // const isBrowserDefaultDark = () =>
  //   window.matchMedia("(prefers-color-scheme: dark)").matches;
  let [darkMode, setDarkMode] = useState(false);
  let [matrix, setMatrix] = useState(null);

  function setBodyBcgClr(color) {
    document.body.style.backgroundColor = color;
  }

  function handleClick() {
    setDarkMode(!darkMode);
    darkMode ? setBodyBcgClr("#f8f8f8") : setBodyBcgClr("#1f1f1f");
    setMatrix(null);
  }

  let location = useLocation();
  console.log(location.pathname);

  const interval = useRef(null);

  interval.current = setInterval(() => {
    setMatrix("");
  }, 15000);

  useEffect(() => {
    if (darkMode) {
      setMatrix(
        <MatrixBackground
          clr1="#1f1f1f"
          clr2="#393939"
          clr3="#1f1f1f1f"
          clr4="#393939"
        />
      );
    } else {
      setMatrix(
        <MatrixBackground
          clr1="#f8f8f8"
          clr2="#f8f8f8"
          clr3="#FFF1"
          clr4="#E3E3E3"
        />
      );
    }
    return () => clearInterval(interval.current);
  }, [darkMode]);

  return (
    <>
      {location.pathname === "/" ? matrix : " "}
      <Box className={darkMode ? Style.dark : Style.light}>
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          minHeight={"100vh"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Navbar darkMode={darkMode} handleClick={handleClick} />
          </Grid>
          <Grid item flexGrow={1}>
            <Routes>
              <Route exact path={"/"} element={<Home />} />
              <Route exact path={"/about"} element={<About />} />
              <Route exact path={"/portfolio"} element={<Portfolio />} />
            </Routes>
          </Grid>
          <Grid item>
            <Box
              component={"footer"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              py={"1.5rem"}
              sx={{ opacity: 0.7 }}
              width={"100%"}
            >
              <p>YӨΛZ with &hearts;</p>
              <p>&copy; {date.getFullYear()}</p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
