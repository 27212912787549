import React, { useEffect, useRef } from "react";
import Style from "./MatrixBackground.module.scss";
import UseWindowDimensions from "./UseWindowDeminision.js";

function MatrixBackground({ timeout = 50, clr1, clr2, clr3, clr4 }) {
  const canvas = useRef();
  const { height, width } = UseWindowDimensions();

  useEffect(() => {
    const context = canvas.current.getContext("2d");
    console.log(width, height);
    canvas.current.width = width;
    canvas.current.height = height;

    context.fillStyle = clr1;
    context.fillRect(0, 0, width, height);

    const columns = Math.floor(width / 20) + 1;
    const yPositions = Array.from({ length: columns }).fill(0);

    context.fillStyle = clr2;
    context.fillRect(0, 0, width, height);

    const matrixEffect = () => {
      context.fillStyle = clr3;
      context.fillRect(0, 0, width, height);

      context.fillStyle = clr4;
      context.font = "15pt monospace";

      yPositions.forEach((y, index) => {
        const text = String.fromCharCode(Math.random() * 128);
        const x = index * 20;
        context.fillText(text, x, y);

        if (y > 100 + Math.random() * 10000) {
          yPositions[index] = 0;
        } else {
          yPositions[index] = y + 20;
        }
      });
    };

    const interval = setInterval(matrixEffect, timeout);
    return () => {
      clearInterval(interval);
    };
  }, [window.innerHeight, window.innerWidth, timeout]);

  return (
    <div
      className={Style.matrixContainer}
      style={{
        // background: "#000000",
        overflow: "hidden",
        position: "fixed",
        height: "100%",
        width: "100%",
        zIndex: -1,
        left: "0",
        top: "0",
      }}
    >
      <canvas ref={canvas} />
    </div>
  );
}

export default MatrixBackground;
