import React, { useState, useEffect, useRef } from "react";
import IconLink from "./IconLink";
import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Mouse } from "@mui/icons-material";

function PortfolioBlock(props) {
  const { image, live, source, title, onSale, showcaseVid, videoUrl } = props;
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const timerRef = useRef(null);

  const handleClickOpen = () => {
    if (showcaseVid) {
      setOpen(true);
      setVideoLoading(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseMove = () => {
    setShowControls(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousemove", handleMouseMove);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [open]);

  const handleMouseOver = (available) => {
    if (!available) {
      document.body.style.cursor = "not-allowed";
    }
  };

  const handleMouseOut = () => {
    document.body.style.cursor = "default";
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      position="relative"
    >
      {!isLoading ? (
        <>
          <Box
            component={"img"}
            src={image}
            alt={"mockup"}
            position="relative"
          />
          {onSale && (
            <Box
              position="absolute"
              sx={{
                top: 50,
                right: 90,
                bgcolor: "red",
                color: "white",
                px: 1,
                py: 0.5,
                borderRadius: 1,
                transform: "rotate(30deg)",
                "@media (min-width:720px)": {
                  top: 65,
                  right: 130,
                  bgcolor: "red",
                  color: "white",
                  px: 2,
                  py: 1,
                  borderRadius: 1,
                },
              }}
            >
              For Sale
            </Box>
          )}
        </>
      ) : (
        <Skeleton>
          <Box
            component={"img"}
            src={image}
            alt={"mockup"}
            onLoad={() => {
              setLoading(false);
            }}
          />
        </Skeleton>
      )}
      <h1 style={{ fontSize: "2rem" }}>{title}</h1>
      <Box
        className={"portfolio"}
        display={"flex"}
        flexDirection={"column"}
        gap={"0.5rem"}
        alignItems={"center"}
        fontSize={"1.5rem"}
        py={"2rem"}
      >
        <Box p={1} border={"2px solid black"} borderRadius={"25px"}>
          <IconButton
            onClick={handleClickOpen}
            disabled={!showcaseVid}
            onMouseOver={() => handleMouseOver(showcaseVid)}
            onMouseOut={handleMouseOut}
          >
            <IconLink
              link={live}
              title={"See Showcase"}
              icon={"fa fa-solid fa-play"}
            />
          </IconButton>
        </Box>
        <Box p={1} border={"2px solid black"} borderRadius={"25px"}>
          {live ? (
            <IconLink link={live} title={"Live Demo"} icon={"fa fa-safari"} />
          ) : (
            <IconLink
              link={"mailto: contact@yoaz.info"}
              title={"Request Demo"}
              icon={"fa fa-safari"}
              onMouseOver={() => handleMouseOver(live)}
              onMouseOut={handleMouseOut}
            />
          )}
        </Box>
        <Box p={1} border={"2px solid black"} borderRadius={"25px"}>
          <IconLink
            link={source}
            title={"Source Code"}
            icon={"fa fa-code"}
            onMouseOver={() => handleMouseOver(source)}
            onMouseOut={handleMouseOut}
          />
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullScreen
        PaperProps={{
          style: { backgroundColor: "black", padding: 0 },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{ position: "absolute", right: 10, top: 10, zIndex: 1 }}
        >
          <CloseIcon style={{ color: "white", fontSize: 50 }} />
        </IconButton>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            position: "relative",
          }}
        >
          {videoLoading && (
            <CircularProgress
              style={{
                position: "absolute",
                color: "white",
              }}
            />
          )}
          <video
            src={videoUrl}
            autoPlay
            loop
            controls={showControls}
            style={{ width: "100%", height: "100%" }}
            onLoadedData={() => setVideoLoading(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default PortfolioBlock;
