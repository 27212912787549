import self from "../img/self.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import mock4 from "../img/mock4.png";
import mock5 from "../img/mock5.png";
import mock6 from "../img/mock6.png";
import mock7 from "../img/mock7.png";
import mock8 from "../img/mock8.png";
import mock7Vid from "../img/mock7Vid.mp4";
import mock2Vid from "../img/mock2Vid.mp4";
import mock8Vid from "../img/mock8Vid.mp4";

export let colors = ["rgb(27, 27, 27)", "rgb(225, 225, 225)"];

export const info = {
  firstName: "Yoaz",
  lastName: "Shmider",
  initials: "YӨΛZ",
  position: "Full Stack Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "☕",
      text: "Fueled by coffee",
    },
    {
      emoji: "🌎",
      text: "Based in Israel",
    },
    {
      emoji: "💼",
      text: "Computer Engineer",
    },
    {
      emoji: "📧",
      text: "contact@yoaz.info",
    },
  ],
  socials: [
    {
      link: "https://instagram.com/yoaz",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "https://github.com/Yoaz",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://linkedin.com/in/yoaz",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    {
      link: "https://twitter.com/iyoaz",
      icon: "fa fa-twitter",
      label: "twitter",
    },
  ],
  bio: "Greetings! I am Yoaz, an accomplished software engineer and entrepreneur. My academic journey led me to the field of Computer Science at Open University, where I honed my intellectual prowess. In addition to my formal education, I embarked on a relentless quest for knowledge, immersing myself in various coding niches through dedicated courses and comprehensive online crash courses. While my primary ardor lies within the realm of Full Stack Development, I am presently channeling a significant portion of my zeal and dedication towards the ever-evolving landscape of WEB3.0. I am impassioned by the boundless potential for pioneering innovations in this domain, with a resolute commitment to reshaping the digital frontier. Should you wish to engage in profound discussions or explore potential collaborations, I extend a warm invitation to connect with me.",
  skills: {
    proficientWith: [
      "Golang",
      "JavaScript",
      "Solidity",
      "Python",
      "React.js",
      "Next.js",
      "C",
      "Backend Development",
      "FrontEnd Development",
      "REST API",
      "MySQL",
      "MangoDB",
      "Blockchain",
      "WEB3.0",
      "Github",
      "Strapi.io",
    ],
    exposedTo: ["NodeJS", "Java", "HTML5", "CSS", "WordPress", "Jira"],
  },
  hobbies: [
    {
      label: "Coding",
      emoji: "👨🏼‍💻",
    },
    {
      label: "Music",
      emoji: "🎧",
    },
    {
      label: "Fitness",
      emoji: "🦾",
    },
    {
      label: "Innnovation",
      emoji: "💡",
    },
  ],
  portfolio: [
    {
      title: "Constructor - FullStack WebApp With CMS",
      image: mock7,
      onSale: true,
      showcaseVid: true,
      videoUrl: mock7Vid,
    },
    {
      title: "NFTerry - WEB3.0 NFT Lottery",
      image: mock6,
      onSale: false,
      showcaseVid: false,
      videoUrl: "",
    },
    {
      title: "MetaCademy - WEB3.0 Academy",
      source: "https://github.com/Yoaz/Yoaz-web3-metacademy-frontend",
      image: mock2,
      showcaseVid: true,
      videoUrl: mock2Vid,
    },
    {
      title: "Bloogy - FullStack WebApp With Golang API",
      image: mock8,
      onSale: false,
      showcaseVid: true,
      videoUrl: mock8Vid,
    },
    {
      title: "The Jungle™- WEB3.0 Short Queue Service",
      source: "https://github.com/Yoaz/The-Jungle-Fast-Rider-FrontEnd",
      image: mock5,
      onSale: false,
      showcaseVid: false,
      videoUrl: "",
    },
    {
      title: "Chelleng'em - Social Network",
      image: mock1,
      showcaseVid: false,
      videoUrl: "",
    },
    {
      title: "Lottery - WEB3.0 Lottery",
      source: "https://github.com/Yoaz/smartcontract-lottery-frontend",
      image: mock3,
      showcaseVid: false,
      videoUrl: "",
    },
    {
      title: "FundMe - WEB3.0 Funding",
      source: "https://github.com/Yoaz/web3-fund-me-app-frontend",
      image: mock4,
      showcaseVid: false,
      videoUrl: "",
    },
  ],
};
